$(document).ready(function () {

  var functions_url = 'https://us-central1-jennymayhew-com.cloudfunctions.net/widgets';

  if (location.hostname === "localhost") {
    functions_url = 'http://localhost:5001/jennymayhew-com/us-central1/widgets'
  }

  $("#form").submit(function (event) {
    $("#loading").removeAttr("hidden");
    let d_form_data = $(this).serializeArray();
    let form_data = {};

    for (let data of d_form_data) {
      form_data[data.name] = data.value;
    }

    fetch(`${functions_url}/sendMessage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(form_data),
    })
      .then(res => res.json())
      .then(data => {
        $(".load").attr("hidden", true);
        $("#cmsg").removeAttr("hidden");
        console.log(data);
        if (data.error) {
          $("#cmsg").html("There has been an error submitting your message.  Please try another method");
          console.log("ERROR");
        } else {
          $("#cmsg").html("Your message has been sent. Thank you!");
        }

      });

    event.preventDefault();
  });
});
